<script lang="ts" setup>

</script>

<template>
  <div class="min-h-screen flex flex-col h-screen w-full">
    <slot name="main" />
    <slot />
  </div>
</template>

<style>

</style>
